<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-title>{{ $t('product') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="wrapper ion-text-center">
        <div class="img-wrapper">
          <ion-img :src="drawer_not_found" alt="drawer" />
        </div>
        <div class="ion-margin-top ion-margin-bottom">
          <ion-label>{{ label }}</ion-label>
        </div>
        <div class="text-center mt-1">
          <ion-text class="ion-text-center dark">
            {{ desc }}
          </ion-text>
        </div>
        <ion-button fill="solid" class="ion-margin-top" @click="onAction">
          {{ btnWording }}
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import drawer_not_found from '@/assets/icons/shared/drawer_not_found.svg';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'errorProductDetail',
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const onAction = () => {
      router.push({ path: '/b2b/main/home' });
    };
    const label = computed(() => {
      return t('cannot_find_product');
    });
    const desc = computed(() => {
      return t('select_another_one');
    });
    const btnWording = computed(() => {
      return t('back_to_home');
    });
    return {
      drawer_not_found,
      label,
      desc,
      btnWording,
      onAction
    };
  }
});
</script>
<style lang="scss" scoped>
.wrapper {
  width: 85%;
  margin: 0 auto;
  top: 20%;
  position: relative;
  .img-wrapper {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.text-center {
  text-align: center;
}
ion-label {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500;
}
ion-button {
  border-radius: 10px;
  text-transform: none;
  width: 100%;
}
ion-text {
  font-weight: 400;
}
.f-w-500 {
  font-weight: 500;
}
ion-icon {
  font-size: 28px;
}
</style>
